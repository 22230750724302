import * as CookieConsent from "vanilla-cookieconsent";

const enableAnalytics = () => {
  console.log("Initializing analytics scripts...");

  const GAId = {
    'gympass.supportroom.com': 'G-Q8QLRKJTPE',
    'boots.supportroom.com': 'G-T1LQG8YKZP',
  };
  // Load Google Analytics dynamically
  const script = document.createElement("script");
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GAId[window.location.hostname] || 'G-LQYBQHE86S';
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", GAId[window.location.hostname] || 'G-LQYBQHE86S', { anonymize_ip: true });
  };
};

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
const cookieConsentConfig = {
  consent_type: "explicit",
  // root: 'body',
  // autoShow: true,
  // disablePageInteraction: true,
  // hideFromBots: true,
  // mode: 'opt-in',
  // revision: 0,

  cookie: {
    name: 'cc_cookie',
    // domain: location.hostname,
    // path: '/',
    // sameSite: "Lax",
    // expiresAfterDays: 365,
  },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: 'cloud inline',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  onFirstConsent: ({ cookie }) => {
    console.log('onFirstConsent fired', cookie);
  },

  onConsent: ({ cookie }) => {
    console.log('onConsent fired!', cookie)
    if (CookieConsent.acceptedCategory('analytics')) {
      // Analytics category enabled
      enableAnalytics()
      // console.log("Analytics category enabled");
    }
  },

  onChange: ({ changedCategories, changedServices }) => {
    console.log('onChange fired!', changedCategories, changedServices);
    if (changedCategories.includes('analytics')) {

      if (CookieConsent.acceptedCategory('analytics')) {
        // Analytics category was just enabled
        // console.log("Analytics category was just enabled");
      } else {
        // Analytics category was just disabled
        // console.log("Analytics category was just disabled");
      }

      if (changedServices['analytics'].includes('Google Analytics')) {
        if (CookieConsent.acceptedService('Google Analytics', 'analytics')) {
          // Google Analytics was just enabled
          // console.log("Google Analytics was just enabled");
        } else {
          // Google Analytics was just disabled
          // console.log("Google Analytics was just disabled");
        }
      }
    }
  },

  onModalReady: ({ modalName }) => {
    console.log('ready:', modalName);
  },

  onModalShow: ({ modalName }) => {
    console.log('visible:', modalName);
  },

  onModalHide: ({ modalName }) => {
    console.log('hidden:', modalName);
  },

  categories: {
    necessary: {
      enabled: true,  // this category is enabled by default
      readOnly: true  // this category cannot be disabled
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^_ga/,   // regex: match all cookies starting with '_ga'
          },
          {
            name: '_gid',   // string: exact cookie name
          }
        ]
      },

      // // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
      // services: {
      //   ga: {
      //     label: 'Google Analytics',
      //     onAccept: () => { },
      //     onReject: () => { }
      //   },
      //   youtube: {
      //     label: 'Youtube Embed',
      //     onAccept: () => { },
      //     onReject: () => { }
      //   },
      // }
    },
    functional: {},
    ads: {
      autoClear: {
        cookies: [
          {
            name: 'YSC',
          },
          {
            name: 'VISITOR_INFO1_LIVE',
          },
          {
            name: '_fbp',
          },
          {
            name: '_gcl_au',
          },
          {
            name: 'test_cookie',
          },
        ]
      },
    },
    unclassified: {
      autoClear: {
        cookies: [
          {
            name: '[abcdef0123456789]{32}',
          },
          {
            name: 'm',
          },
          {
            name: '_gcl_aw',
          },
          {
            name: 'gclid_info',
          },
        ]
      },
    }
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
          // closeIconLabel: 'Reject all and close modal',
          // footer: `
          //               <a href="#path-to-impressum.html" target="_blank">Impressum</a>
          //               <a href="#path-to-privacy-policy.html" target="_blank">Privacy Policy</a>
          //           `,
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your Privacy Choices',
              description: `I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data.`,
            },
            {
              title: 'Strictly Necessary',
              description: 'Strictly necessary cookies allow core website functionality such as user login and account management. The website cannot be used properly without strictly necessary cookies.',
              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'necessary'
            },
            {
              title: 'Performance and Analytics',
              description: 'Performance cookies are used to see how visitors use the website, eg. analytics cookies. Those cookies cannot be used to directly identify a certain visitor',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description'
                },
                body: [
                  {
                    name: '_ga',
                    domain: "Google LLC .cookie-script.com",
                    desc: "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service.This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier.It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.",
                  },
                  {
                    name: '_ga_6HZSPS6QTR',
                    domain: ".cookie-script.com",
                    desc: 'This cookie is used by Google Analytics to persist session state.',
                  }
                ]
              }
            },
            {
              title: 'Targeting and Advertising',
              description: 'Targeting cookies are used to identify visitors between different websites, eg. content partners, banner networks. Those cookies may be used by companies to build a profile of visitor interests or show relevant ads on other websites.',
              linkedCategory: 'ads',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description'
                },
                body: [
                  {
                    name: 'YSC',
                    domain: "Google LLC .youtube.com",
                    desc: "This cookie is set by YouTube to track views of embedded videos.",
                  },
                  {
                    name: 'VISITOR_INFO1_LIVE',
                    domain: "Google LLC .youtube.com",
                    desc: 'This cookie is set by Youtube to keep track of user preferences for Youtube videos embedded in sites; it can also determine whether the website visitor is using the new or old version of the Youtube interface.',
                  },
                  {
                    name: '_fbp',
                    domain: "Meta Platform Inc. .cookie-script.com",
                    desc: 'Used by Meta to deliver a series of advertisement products such as real time bidding from third party advertisers',
                  },
                  {
                    name: '_gcl_au',
                    domain: "Google LLC .cookie-script.com",
                    desc: 'Used by Google AdSense for experimenting with advertisement efficiency across websites using their services',
                  },
                  {
                    name: 'test_cookie',
                    domain: "Google LLC .doubleclick.net",
                    desc: "This cookie is set by DoubleClick(which is owned by Google) to determine if the website visitor's browser supports cookies.",
                  }
                ]
              }
            },
            {
              title: 'Functionality',
              description: 'Functionality cookies are used to remember visitor information on the website, eg. language, timezone, enhanced content.',
              linkedCategory: 'functional'
            },
            {
              title: 'Unclassified',
              description: 'Unclassified cookies are cookies that do not belong to any other category or are in the process of categorization.',
              linkedCategory: 'unclassified',
              cookieTable: {
                caption: 'Cookie table',
                headers: {
                  name: 'Cookie',
                  domain: 'Domain',
                  desc: 'Description'
                },
                body: [
                  {
                    name: '[abcdef0123456789]{32}',
                    domain: ".cookie-script.com",
                    desc: "",
                  },
                  {
                    name: 'm',
                    domain: "Stripe m.stripe.com",
                    desc: "",
                  },
                  {
                    name: '_gcl_aw',
                    domain: "Google .cookie-script.com",
                    desc: "",
                  },
                  {
                    name: 'gclid_info',
                    domain: ".cookie-script.com",
                    desc: "",
                  },
                ]
              }
            },
            // {
            //   title: 'More information',
            //   description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
            // }
          ]
        }
      }
    }
  }
};
export default cookieConsentConfig;